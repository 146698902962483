/* You can add global styles to this file, and also import other style files */
@import 'variables';
@import 'mixins';
@import 'functions';
@import 'typography';
@import 'base';
@import 'layout';
@import 'utilities';

/* PrimeNG ICONS*/
@import 'primeicons/primeicons.css';

/* PrimeNG */
@import './themes/mytheme/theme.scss';
@import '../../node_modules/primeng/resources/primeng.css';

/* Angular CDK */
@import '@angular/cdk/overlay-prebuilt.css';
