.p-accordion {
  .p-accordion-header {
    .p-accordion-header-link {
      padding: $accordionHeaderPadding 0;
      color: $primary-dnv-medium-blue;
      font-weight: 600;
      border-radius: $borderRadius;
      transition: $listItemTransition;
      font-size: pxToRem(14);

      .p-accordion-toggle-icon {
        margin-right: $inlineSpacing;
      }

      .p-accordion-toggle-icon-end {
        margin-left: $inlineSpacing;
      }
    }

    &:not(.p-disabled) {
      .p-accordion-header-link {
        &:focus-visible {
          @include focused-inset();
        }
      }
    }

    &:not(.p-disabled).p-highlight {
      .p-accordion-header-link {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  .p-accordion-content {
    padding: pxToRem(16);
    background: $basic-tones-dnv-white;
    color: $black;
    border-radius: pxToRem(10);
  }

  p-accordiontab {
    .p-accordion-tab {
      margin-bottom: $accordionSpacing;
    }

    @if $accordionSpacing == 0 {
      .p-accordion-header {
        .p-accordion-header-link {
          border-radius: 0;
        }
      }

      &:not(:first-child) {
        .p-accordion-header {
          .p-accordion-header-link {
            border-top: 0 none;
          }

          &:not(.p-highlight):not(.p-disabled):hover,
          &:not(.p-disabled).p-highlight:hover {
            .p-accordion-header-link {
              border-top: 0 none;
            }
          }
        }
      }

      &:first-child {
        .p-accordion-header {
          .p-accordion-header-link {
            border-top-right-radius: $borderRadius;
            border-top-left-radius: $borderRadius;
          }
        }
      }

      &:last-child {
        .p-accordion-header:not(.p-highlight) {
          .p-accordion-header-link {
            border-bottom-right-radius: $borderRadius;
            border-bottom-left-radius: $borderRadius;
          }
        }

        .p-accordion-content {
          border-bottom-right-radius: $borderRadius;
          border-bottom-left-radius: $borderRadius;
        }
      }
    }
  }
}
