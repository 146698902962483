.p-toast {
  min-width: pxToRem(280);

  .p-toast-message {
    margin: $toastMargin;
    box-shadow: $toastShadow;
    color: $black;
    border-radius: pxToRem(10);

    .p-toast-message-content {
      padding: pxToRem(12);
      border-width: $toastBorderWidth;
      display: flex;
      align-items: center;
      gap: pxToRem(10);

      .p-toast-message-text {
        margin: $toastMessageTextMargin;
      }
      .p-toast-message-icon {
        font-size: $toastIconFontSize;
      }

      .p-icon:not(.p-toast-icon-close-icon) {
        width: $toastIconFontSize;
        height: $toastIconFontSize;
      }

      .p-toast-summary {
        font-weight: $toastTitleFontWeight;
      }

      .p-toast-detail {
        margin: $toastDetailMargin;
      }
    }

    .p-toast-icon-close {
      width: $toastIconFontSize;
      height: $toastIconFontSize;
      border-radius: $actionIconBorderRadius;
      background: transparent;
      transition: $actionIconTransition;
      margin-left: auto;
      color: $black;

      &:focus-visible {
        @include focused();
      }
    }

    &.p-toast-message-info {
      background: $light-cyan;
    }

    &.p-toast-message-success {
      background: $message-success-bg;
    }

    &.p-toast-message-warn {
      background: $message-warning-bg;
    }

    &.p-toast-message-error {
      background: $misty-rose;
    }

    &.p-toast-message-alert {
      background: $peach;
    }
  }
}
