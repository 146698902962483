/*
Set base styles for HTML elements, such as headings, paragraphs, lists, etc.
*/
html {
  box-sizing: border-box;
  font-size: $remSize;
}

body {
  height: 100vh;
}

body,
body .p-component {
  font-family: 'Nunito Sans', sans-serif;
}

*:not(
    :where([class*='p-'], [class*='pi-'], [class*='p-'] th, [class*='p-'] td)
  ) {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.p-overflow-hidden {
  margin: 0;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
