/*
Store all your global variables, such as colors, font sizes, breakpoints, and other reusable values.
*/
$basic-tones-dnv-dark: #1a1a19;
$basic-tones-dnv-light: #e6e6e5;
$basic-tones-dnv-ultra-light: #f8f7f7;
$basic-tones-dnv-medium: #908d89;
$basic-tones-dnv-medium-light: #cccbc9;
$basic-tones-dnv-white: #ffffff;
$basic-tones-dnv-medium-dark: #4f4d4a;
$basic-tones-dnv-medium-gray: #707070;
$primary-dnv-dark-blue: #0f204b;
$primary-dnv-medium-blue: #003591;
$primary-dnv-light-blue: #4d72b2;
$primary-dnv-sky-light-blue: #e9f7fc;
$secondary-dnv-active-sky-blue: #21acde;
$tertiary-dnv-ultra-light-earth: #21acde;
$input-error-red: #e24c4c;
$background-white: #fbf8f3;
$table-body-row-even-bg: #fbfbfb;
$active-filter-tag-bg: #f2fafd;
$active-filter-tag-text: #4b5563;
$home-header-bg: #d9edf7;
$page-details-header-bg: #ecf6fb;
$black: #000000;
$deep-saphire-blue: #0f378b;
$sky-breeze-blue: #a8d7ee;
$sky-blue: #99d9f0;
$urban-stone-gray: #82817c;
$midnight-navy: #132049;
$regal-blue: #1e3a8a;
$dusk-blue: #1e293b;
$icy-sky-blue: #d3eef8;
$crimson-flame: #eb2a34;
$button-disabled-text: #707070;
$stone-gray: #9c9a96;

$remSizeNo: 14;
$remSize: $remSizeNo + px;

// for max-width usage
$breakpoint-sm: 574px;
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1199px;
$breakpoint-rec-zoom: 1299px;

$summer-sky: #4a9dd6;
$summer-sky-alt: #007fad;
$sunflower-yellow: #fbea46;
$fern-green: #5c9a40;
$ash-grey: #b6b4b1;
$firebrick-red: #d63b3d;
$misty-rose: #fde8e9;
$light-yellow: #fef9b9;
$light-cyan: #e5f8ff;
$pastel-green: #d1eac8;
$concrete: #f3f2f2;
$peach: #fef1e8;
$cyan: #009fda;
$forest-green: #3f9c35;
$forest-green-alt: #2d8721;
$sunshine-yellow: #ffe900;
$pewter-gray: #b5b3b0;

$message-closed-bg: $concrete;
$message-closed-bc: #908d89;
$message-warning-bg: #fffde5;
$message-warning-bc: #ffe900;
$message-success-bg: #edf9ec;
$message-success-bc: #3f9c35;

$status-to-be-confirmed: #f97316;
$vivid-orange: #f97316;

$button-focus-shadow: none;
$default-border-radius: 8px;
$button-border-radius: $default-border-radius;
$border-radius-checkbox: 6px;
$button-font-weight: 700;
$button-primary-text: $basic-tones-dnv-white;
$button-disabled-text: $basic-tones-dnv-medium-gray;
$button-disabled-background: $basic-tones-dnv-light;

$breadcrumb-icon-separator-color: #696763;

/*
Defines horizontal margins for shared-grid component when used on various pages
for multiple screen sizes.
*/
$table-list-margin-size: pxToRem(38);
$table-list-margin-size-sm: pxToRem(20);
$table-list-margin-size-md: pxToRem(32);
$table-details-margin-size: pxToRem(88);
$table-details-margin-size-sm: pxToRem(16);
$table-details-margin-size-md: pxToRem(24);
$table-details-margin-size-xl: pxToRem(32);
