/*
Define global typographic styles, such as font families, sizes, and weights.
*/
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

@font-face {
  font-family: 'DNVDisplay';
  src: url('../assets/fonts/DNVDisplay/DNVDisplay-Regular-Web.woff')
    format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'Nunito Sans';
}
