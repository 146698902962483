/*
Define global mixins to be reused across components.
*/

@mixin shared-grid-internal($spacing) {
  margin-left: $spacing;
  margin-right: $spacing;

  ::ng-deep {
    .p-paginator .p-dropdown {
      margin-right: 0;
      padding: 0;
    }
    .p-paginator-right-content {
      margin-left: $spacing;
      margin-right: $spacing;
    }
  }
}

@mixin shared-grid($spacing-list: pxToRem(38), $spacing-details: pxToRem(88)) {
  shared-grid {
    &.table-list {
      @include shared-grid-internal($spacing-list);
    }

    &.table-details {
      @include shared-grid-internal($spacing-details);
    }
  }
}

@mixin grid-parent {
  .page-title {
    font-family: 'DNVDisplay';
    font-weight: 400;
    font-size: pxToRem(36);
    text-transform: uppercase;
    color: $primary-dnv-dark-blue;
    padding: 0 pxToRem(40) pxToRem(41) pxToRem(38);
    background-color: $home-header-bg;
  }

  @media screen and (max-width: $breakpoint-md) {
    .page-title {
      padding: 0 pxToRem(32) pxToRem(32) pxToRem(32);
      font-size: pxToRem(32);
    }
  }

  @media screen and (max-width: $breakpoint-sm) {
    .page-title {
      padding: 0 pxToRem(20) pxToRem(28) pxToRem(20);
      font-size: pxToRem(28);
    }
  }
}

@mixin states-colors {
  &.misty-rose {
    background-color: $misty-rose;
  }

  &.light-yellow {
    background-color: $light-yellow;
  }

  &.light-cyan {
    background-color: $light-cyan;
  }

  &.pastel-green {
    background-color: $pastel-green;
  }

  &.sunflower-yellow {
    color: $sunflower-yellow;
  }

  &.fern-green {
    color: $fern-green;
  }

  &.ash-grey {
    color: $ash-grey;
  }

  &.vivid-orange {
    color: $vivid-orange;
  }

  &.summer-sky {
    color: $summer-sky;
  }

  &.firebrick-red {
    color: $firebrick-red;
  }

  &.forest-green {
    color: $forest-green;
  }

  &.sunshine-yellow {
    color: $sunshine-yellow;
  }

  &.crimson-flame {
    color: $crimson-flame;
  }

  &.pewter-gray {
    color: $pewter-gray;
  }
}
